import request from "@/core/services/axios";
// 备课组长-考试情况
export function bkzzExamInfo(query) {
  return request({
    url: "/qa/workbench/bkzz/exam/info",
    method: "get",
    params: query,
  });
}
// 校领导-考试情况
export function xldExamInfo(query) {
  return request({
    url: "/qa/workbench/xld/exam/info",
    method: "get",
    params: query,
  });
}
// 年级组长-考试情况
export function njzzExamInfo(query) {
  return request({
    url: "/qa/workbench/njzz/exam/info",
    method: "get",
    params: query,
  });
}
// 任课教师-考试情况
export function rkjsExamInfo(query) {
  return request({
    url: "/qa/workbench/rkjs/exam/info",
    method: "get",
    params: query,
  });
}
// 班主任-考试情况
export function bzrExamInfo(query) {
  return request({
    url: "/qa/workbench/bzr/exam/info",
    method: "get",
    params: query,
  });
}
// 全部通用-待审核列表
export function examineList(query) {
  return request({
    url: "/qa/workbench/common/examine/list",
    method: "get",
    params: query,
  });
}
// 全部通用-待批阅列表
export function markList(query) {
  return request({
    url: "/qa/workbench/common/mark/list",
    method: "get",
    params: query,
  });
}
// 进行中考试列表
export function examingList(query) {
  return request({
    url: "/qa/workbench/common/examing/list",
    method: "get",
    params: query,
  });
}
// 进行中考试列表
export function reportList(query) {
  return request({
    url: "/qa/workbench/common/report/list",
    method: "get",
    params: query,
  });
}
// 教务-平台基本情况
export function basicStatistics(query) {
  return request({
    url: "/qa/workbench/educational/basic/statistics",
    method: "get",
    params: query,
  });
}
// 教务-考试情况概述 教务-考试情况概述 (教务三个接口(考试情况/各学科考试详情/各年级考试详情)都用此接口：通过参数获取不同维度数据)
export function examPreview(query) {
  return request({
    url: "/qa/workbench/educational/exam/preview",
    method: "get",
    params: query,
  });
}
// 通用-家长端App关注度
export function commonAttention(query) {
  return request({
    url: "/qa/workbench/common/attention",
    method: "post",
    data: query,
  });
}
// 通用-平台各模块使用频次-教师
export function commonFrequency(query) {
  return request({
    url: "/qa/workbench/common/frequency",
    method: "post",
    data: query,
  });
}
// 根据id获取报告详情
export function reportStat(id) {
  return request({
    url: "/report/stat/" + id,
    method: "get",
  });
}
// 全部通用-获取教师角色权限
export function commonAuthority() {
  return request({
    url: "/qa/workbench/common/authority",
    method: "get",
  });
}
// 重点关注学生-获取报告信息
export function importstudent(data) {
  return request({
    url: "/qa/workbench/common/import/student",
    method: "post",
    data: data,
  });
}
// 班主任-任课教师-学科考情
export function examSituation(data) {
  return request({
    url: "/qa/workbench/bzr/exam/situation",
    method: "post",
    data: data,
  });
}
// 年级组长-备课组长-学科考情
export function njzzexamSituation(data) {
  return request({
    url: "/qa/workbench/njzz/exam/situation",
    method: "post",
    data: data,
  });
}
// 作业列表
export function homeworklist(data) {
  return request({
    url: "/qa/workbench/common/homework/list",
    method: "get",
    data: data,
  });
}
