<template>
  <div class="APPattention">
    <div class="sub-tile">家长端App关注度</div>
    <div v-if="teacherRoleId == 0" class="check-item-box">
      <div
        v-for="(item, index) in levelOptions"
        :key="index"
        :class="{ on: item.value == levelId }"
        @click="checkLevel(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <template v-if="listLoading || tableData.length > 0">
      <div class="tips-msg">
        <div v-html="description"></div>
      </div>
      <div v-show="$parent.chartType">
        <template v-if="teacherRoleId == 4">
          <div>
            <el-select v-model="searchType" placeholder="" @change="checkTab()">
              <el-option label="累计使用频次" :value="1"> </el-option>
              <el-option label="家长关注占比" :value="2"> </el-option>
            </el-select>
            <appChart ref="appChart"></appChart>
          </div>
        </template>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            v-if="teacherRoleId == 5 || teacherRoleId == 0"
            prop="year"
            align="center"
            label="年级"
            width="116"
          >
          </el-table-column>
          <el-table-column
            v-if="teacherRoleId == 4 || teacherRoleId == 2"
            prop="classNum"
            align="center"
            label="班级"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="studentNum"
            align="center"
            label="学生人数"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="frequency"
            align="center"
            label="累计使用频次"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="unLoginNum"
            align="center"
            label="未登录人数"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="unLoginProportion"
            align="center"
            label="未登录占比"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="attentionNum"
            align="center"
            label="家长关注数"
            width="116"
          >
          </el-table-column>
          <el-table-column
            prop="attentionProportion"
            align="center"
            label="关注占比"
          >
          </el-table-column>
        </el-table>
        <div
          v-if="oldList.length > 5"
          class="more-box"
          :class="{ 'more-box-on': !showTab }"
          @click="checkMore()"
        >
          <div>
            <i class="el-icon-d-arrow-right"></i>
            <template v-if="!showTab"> 展开 </template>
            <template v-else> 收起 </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          <div>暂无学生家长关注数据</div>
        </div>
      </noData>
    </template>
  </div>
</template>
<script>
import { levelOptions } from "@/core/util/constdata";
import appChart from "./appChart.vue";
import { commonAttention } from "@/core/api/dashboard/index";
export default {
  name: "APPattention",
  components: { appChart },
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      levelOptions: levelOptions(),
      levelId: 3,
      tableData: [],
      oldList: [],
      searchType: 1,
      description: "",
      showTab: false,
      listLoading: false,
    };
  },
  created() {
    this.levelId = this.levelOptions[0].value;
    // this.init();
  },
  methods: {
    checkTab() {
      this.$refs.appChart.init();
    },
    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    checkLevel(item) {
      if (this.listLoading) return;
      this.levelId = item.value;
      this.getcommonAttention();
    },
    getcommonAttention() {
      let data = JSON.parse(JSON.stringify(this.$parent.filterObj));
      if (this.teacherRoleId == 0) {
        data.level = this.levelId;
      }
      data.roleType = this.teacherRoleId;
      this.listLoading = true;
      commonAttention(data)
        .then((res) => {
          this.listLoading = false;
          this.description = res.data.data.description;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.$refs.appChart.init();
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
    },
    init() {
      this.getcommonAttention();
    },
  },
};
</script>
<style lang="scss" scoped>
.APPattention {
}
</style>
