<template>
  <div class="setting">
    <div class="title">
      常用功能
      <el-tooltip
        class="item"
        effect="dark"
        content="常用功能设置"
        placement="top"
      >
        <img
          src="@/assets/dashborad/icon-setting.png"
          alt=""
          @click="getApplicationList()"
        />
      </el-tooltip>
    </div>
    <div class="setting-box">
      <div
        v-for="(item, index) in applicationList"
        :key="index"
        class="setting-item"
        @click="linkTo(item)"
      >
        <img :src="item.icon + '?time=' + new Date().getTime()" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <el-dialog
      title="常用功能"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="348px"
      custom-class="setting"
    >
      <div class="app">
        <div class="app-title">当前设置<span>（最多设置8个哦）</span></div>
        <div class="setting-box">
          <div
            v-for="(item, index) in appNowArr"
            :key="index"
            class="setting-item"
          >
            <div class="icon">
              <img :src="item.icon + '?time=' + new Date().getTime()" alt="" />
              <img
                class="operation-icon"
                src="@/assets/dashborad/icon-clear.png"
                alt=""
                @click="delNowArr(index)"
              />
            </div>
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="app-title" style="margin-top: 18px">管理常用功能</div>
        <div class="setting-box" style="margin-bottom: 18px">
          <div
            v-for="(item, index) in diffAppArr"
            :key="index"
            class="setting-item"
          >
            <div class="icon">
              <img :src="item.icon + '?time=' + new Date().getTime()" alt="" />
              <img
                class="operation-icon"
                src="@/assets/dashborad/icon-add.png"
                alt=""
                @click="addNowArr(item, index)"
              />
            </div>
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="submitLoading"
          @click="setApp()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { applicationList, setApplication } from "@/core/api/message/index";
import { usercenter } from "@/core/api/login";
export default {
  name: "Setting",
  data() {
    return {
      applicationList: [],
      appNowArr: [],
      diffAppArr: [],
      dialogVisible: false,
      submitLoading: false,
    };
  },
  created() {
    this.getApp();
  },
  methods: {
    linkTo(item) {
      this.$router.push({
        path: item.uri,
      });
    },
    setApp() {
      this.submitLoading = true;
      let data = this.appNowArr.map((item, index) => {
        return {
          applicationId: item.id,
          priority: index + 1,
        };
      });
      setApplication(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "常用功能设置成功!",
            type: "success",
          });

          this.submitLoading = false;
          this.getApp();
          this.dialogVisible = false;
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    delNowArr(index) {
      this.appNowArr.splice(index, 1);
      this.initDiffApp();
    },
    initDiffApp() {
      const appNowIdArr = this.appNowArr.map((item) => item.id);
      this.diffAppArr = this.applicationArr.filter(
        (item) => !appNowIdArr.includes(item.id)
      );
      this.appLoading = false;
    },
    addNowArr(item) {
      if (this.appNowArr.length < 8) {
        this.appNowArr.push({ ...item });
        this.initDiffApp();
      } else {
        this.$message({
          showClose: true,
          message: "最多设置8个常用功能!",
          type: "error",
        });
      }
    },
    async getApplicationList() {
      const res = await applicationList();
      this.applicationArr = res.data.data;
      this.dialogVisible = true;
      this.initDiffApp();
    },
    getApp() {
      this.appLoading = true;
      usercenter().then((response) => {
        const { applicationList, trumpetInfo } = response.data.data;
        this.applicationList = [...applicationList];
        this.appNowArr = [...applicationList];
        this.trumpetInfo = trumpetInfo;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.setting {
  .app-title {
    color: #0a1119;
    font-size: 14px;
    font-weight: bold;
  }
  .title {
    font-size: 18px;
    color: #0a1119;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    img {
      cursor: pointer;
    }
  }
  .setting-box {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    .setting-item {
      min-width: 25%;
      text-align: center;
      margin-top: 18px;
      cursor: pointer;
      .icon {
        position: relative;
        .operation-icon {
          position: absolute;
          right: 6px;
          top: -6px;
          cursor: pointer;
        }
      }
      div {
        color: #0a1119;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
</style>
