<template>
  <div class="numberOfUse">
    <div
      id="numberOfUse"
      ref="numberOfUse"
      style="width: 560px; height: 200px"
    ></div>
  </div>
</template>
<script>
export default {
  name: "NumberOfUse",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getNums(key, list) {
      let num = 0;
      list.map((item) => {
        num = num + Number(item[key]);
      });
      return num;
    },
    init(list) {
      const echarts = require("echarts");
      const chartDom = this.$refs.numberOfUse;
      const myChart = echarts.init(chartDom);
      let searchType = this.$parent.searchType;

      let str = "模块使用频次";
      let resStr = "resourceFrequency";
      let markStr = "markFrequency";
      let sheetStr = "sheetFrequency";
      let formStr = "formFrequency";
      let reportStr = "reportFrequency";

      if (searchType == 2) {
        str = "使用人数（去重）";
        resStr = "resourceCount";
        markStr = "markCount";
        sheetStr = "sheetCount";
        formStr = "formCount";
        reportStr = "reportCount";
      }

      const option = {
        grid: {
          left: "12%",
          right: "0%",
          bottom: "10%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          textStyle: { color: "#ffffff", fontWeight: "normal" },
          backgroundColor: "rgba(37,38,45,0.8);",
          borderColor: "rgba(37,38,45,0.8);",
          formatter: function (params) {
            return (
              str +
              "<br/>" +
              params[0].marker +
              params[0].axisValue +
              " " +
              params[0].value
            );
          },
        },
        xAxis: {
          type: "category", //添加以下配置
          axisTick: {
            show: false,
          },
          data: [
            "资源中心",
            "主观题阅卷",
            "答题卡制作",
            "统计报表",
            "统计报告",
          ],
        },
        color: ["rgba(101,159,247,0.85)"],
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [
              this.getNums(resStr, list),
              this.getNums(markStr, list),
              this.getNums(sheetStr, list),
              this.getNums(formStr, list),
              this.getNums(reportStr, list),
            ],
            type: "bar",
            barMaxWidth: "40px",
            barGap: "80%",
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.numberOfUse {
  #numberOfUse {
    margin: 18px auto;
  }
}
</style>
