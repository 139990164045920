import request from "@/core/services/axios";
export function getmessagesNum() {
  // 获取消息未读条数
  return request({
    url: `/qa/messagecenter/getmessages`,
    method: "get",
    headers: {
      version: 1106,
    },
  });
}
export function messagecenterPage(params) {
  // 取消息列表
  return request({
    url: `/qa/messagecenter/page/${params.type}`,
    method: "get",
    params: params,
  });
}
export function updateIsRead(params) {
  // 标记type类型的消息为全部已读
  return request({
    url: `/qa/messagecenter/updateIsRead/${params.type}`,
    method: "post",
  });
}
export function messageDetail(params) {
  // 获取消息详情(根据类型和ID)
  return request({
    url: `/qa/messagecenter/detail/${params.type}`,
    method: "get",
    params: params,
  });
}
export function getNewMessage() {
  // 最新消息
  return request({
    url: `/qa/messagecenter/message/new`,
    method: "get",
  });
}
export function applicationList() {
  // 查看应用列表
  return request({
    url: `/qa/application/list`,
    method: "get",
  });
}

export function setApplication(params) {
  // 设置应用
  return request({
    url: `/qa/application/setApplication`,
    method: "post",
    data: params,
  });
}
