<template>
  <div ref="flexdL" class="information">
    <div class="user-msg item-box">
      <div class="date-box">
        <img src="@/assets/dashborad/icon-time.png" alt="" />
        今日：
        <!-- 在有节日的时候  显示不下 所以注释了 -->
        <div>{{ nowDate }}&nbsp;</div>
        <div>农历{{ lunar }}</div>
        <!-- <div>{{ holiday }}</div> -->
      </div>
      <div class="user-info">
        <div class="img-box">
          <img :src="avatar" alt="" />
        </div>
        <div class="user-cnt" @click="$router.push({ name: 'userinfo' })">
          <div class="name">您好! {{ userInfo.name }}</div>
          <div class="tips-box">
            <div v-if="userInfo.roleId == 1">超级管理员</div>
            <div v-if="userInfo.roleId == 3 || userInfo.roleId == 6">
              管理员
            </div>
            <template v-if="userInfo.roleId == 5">
              <template v-for="(item, index) in userRoleName">
                <div v-if="index < 2" :key="index">{{ item }}</div>
              </template>
              <el-tooltip placement="top">
                <div slot="content">
                  <template v-for="(item, index) in userRoleName">
                    <span v-if="index > 1" :key="index"
                      >{{ item
                      }}<template v-if="index != userRoleName.length - 1"
                        >,</template
                      >
                    </span>
                  </template>
                </div>
                <div v-if="userRoleName.length > 2" class="more-role-name">
                  ...
                </div>
              </el-tooltip>
            </template>
          </div>
        </div>
      </div>
    </div>
    <taskItem ref="taskItem" class="item-box"></taskItem>
    <setting v-if="!isExpried" class="item-box"></setting>
    <div class="item-box code-box">
      <div class="item-tips">
        <img src="@/assets/dashborad/icon-sweep.png" alt="" />
        扫一扫，下载教师App
      </div>
      <div class="down-app" style="margin-bottom: 18px">
        <img src="@/assets/dashborad/teacher-app.png" alt="" />
      </div>
      <div class="item-tips">
        <img src="@/assets/dashborad/icon-sweep.png" alt="" />
        扫一扫，下载学生App
      </div>
      <div class="down-app">
        <img src="@/assets/dashborad/student-app.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { getLunar } from "@/core/util/lunar";
import { getStore } from "@/core/util/store";
import taskItem from "./taskItem.vue";
import setting from "./setting.vue";
import { isExpiredForPath } from "@/core/util/util";
export default {
  name: "Information",
  components: { taskItem, setting },
  props: {},
  data() {
    return {
      holiday: "",
      nowDate: "",
      lunar: "",
      weekDay: "",
      avatar: "",
      userInfo: {},
      userRoleName: [],
      teacherRoleId: "",
      postionBox: false,
      left: 0,
      isExpried: false,
    };
  },
  created() {
    this.initTime();
  },
  mounted() {
    //给window添加一个滚动滚动监听事件
    // window.addEventListener("scroll", this.handleScroll);
    this.isExpried = isExpiredForPath() ? true : false;
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // handleScroll() {
    //   let scrollTop = 0;
    //   if (document.documentElement && document.documentElement.scrollTop) {
    //     scrollTop = document.documentElement.scrollTop;
    //   } else if (document.body) {
    //     scrollTop = document.body.scrollTop;
    //   }
    //   let dom = document.getElementsByClassName("dashboard-left")[0];
    //   if (dom) {
    //     // let position = dom.getBoundingClientRect();
    //     // this.left = position.left + position.width + 18;
    //   }
    //   if (scrollTop > 15) {
    //     // this.postionBox = true;
    //   } else {
    //     // this.postionBox = false;
    //   }
    // },
    init() {
      this.teacherRoleId = this.$parent.teacherRoleId;
      this.userInfo = getStore({
        name: "userInfo",
      });
      this.avatar = this.userInfo.avatar;
      if (this.userInfo.roleId == 5 && this.userInfo.teacherRole) {
        this.userRoleName = this.userInfo.teacherRole.split("、");
      }
      if (!this.avatar) {
        this.avatar = "https://static.wtjy.com/resource/avatar/131.jpg";
      }
      this.$refs.taskItem.init();
    },
    initTime() {
      let dateTime = new Date();
      let weeks = new Array(
        "周日",
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六"
      );
      let days = dateTime.getDay();
      this.weekDay = weeks[days];
      const { m, d } = getLunar();
      this.lunar = m + d;
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate(9);
      if (this.lunar === "八月十五") {
        this.holiday = "中秋节";
      } else if (month === 1 && day === 1) {
        this.holiday = "元旦节";
      } else if (month === 10 && day === 1) {
        this.holiday = "国庆节";
      } else if (month === 4 && day === 5) {
        this.holiday = "清明节";
      } else if (month === 6 && day === 1) {
        this.holiday = "儿童节";
      } else if (month === 9 && day === 10) {
        this.holiday = "教师节";
      }
      this.nowDate = dateTime.getFullYear() + "年" + month + "月" + day + "日";
    },
  },
};
</script>

<style lang="scss" scoped>
.fd-box {
  // position: fixed;
  // position: sticky;
  // top: 78px;
}
.code-box {
  position: sticky;
  top: 82px;
  right: 0;
}

.information {
  width: 340px;
  line-height: 1;
  min-height: 200px;
  margin-bottom: 20px;
  // position: sticky;
  // height: calc(100vh - 90px);
  right: 0;
  z-index: 9;
  .item-tips {
    background: #edf5ff;
    border: 1px solid #cae1f7;
    padding: 4px 0;
    text-align: center;
    border-radius: 4px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -16px;
      width: 0px;
      height: 0px;
      border: 8px solid transparent;
      border-top: 8px solid #cae1f7;
    }
    &::before {
      content: "";
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: -15px;
      z-index: 1;
      width: 0px;
      height: 0px;
      border: 8px solid transparent;
      border-top: 8px solid #edf5ff;
    }
  }
  .down-app {
    background-image: url("~@/assets/dashborad/app-bg.png");
    background-size: 100% 100%;
    margin-top: 18px;
    text-align: center;
    padding: 16px 0;
    border-radius: 4px;
    overflow: hidden;
  }
  .item-box {
    margin-bottom: 18px;
    background: #ffffff;
    padding: 18px;
    border-radius: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .user-msg {
    .tips-box {
      display: flex;
      margin-top: 18px;
      .more-role-name {
        cursor: pointer;
      }
      div {
        margin-right: 8px;
        background: #f5f6f8;
        border-radius: 4px;
        color: #3e4551;
        padding: 6px 8px;
      }
    }
    .img-box {
      width: 70px;
      height: 70px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        flex-shrink: 0;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      margin-top: 18px;
      .user-cnt {
        margin-left: 12px;
        cursor: pointer;
        .name {
          width: 203px;
          font-size: 18px;
          color: #0a1119;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .date-box {
      display: flex;
      align-items: center;
      color: #83878f;
      img {
        margin-right: 4px;
      }
    }
  }
}
</style>
