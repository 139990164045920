<template>
  <div class="appChart">
    <div ref="appChart" style="height: 243px"></div>
  </div>
</template>
<script>
export default {
  name: "AppChart",
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    isLastChar(str, char) {
      str = str.toString();
      return str.slice(-1) === char;
    },
    init() {
      const echarts = require("echarts");
      const chartDom = this.$refs.appChart;
      const myChart = echarts.init(chartDom);
      let searchType = this.$parent.searchType;
      let list = this.$parent.oldList;
      let str = "累计使用频次";
      let key = "frequency";
      if (searchType == 2) {
        str = "家长关注占比";
        key = "attentionProportion";
      }
      let sourceArr = list.map((item) => {
        let data = {
          product: this.isLastChar(item.classNum, "班")
            ? "item.classNum"
            : item.classNum + "班",
          班级: item[key],
        };
        return data;
      });

      const option = {
        legend: {
          show: false,
        },
        grid: {
          left: "10%",
          right: "8%",
          bottom: "30%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: { color: "#ffffff", fontWeight: "normal" },
          backgroundColor: "rgba(37,38,45,0.8);",
          borderColor: "rgba(37,38,45,0.8);",
          formatter: function (params) {
            return (
              str +
              "<br/>" +
              params[0].marker +
              params[0].axisValue +
              " " +
              params[0].value["班级"]
            );
          },
        },
        dataset: {
          dimensions: ["product", "班级"],
          source: sourceArr,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
        },
        yAxis: {},
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: (8 / sourceArr.length) * 100,
          },
          {
            type: "slider",
          },
        ],
        color: ["#4D8DEFD9"],
        series: [{ type: "bar", barMaxWidth: "40px", barGap: "80%" }],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.appChart {
  margin: 18px 0;
}
</style>
