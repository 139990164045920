<template>
  <div class="taskItem">
    <div class="check-box">
      <div
        v-for="(item, index) in checkArr"
        :key="index"
        :class="{ on: checkType == item.value, 'check-nums': item.nums > 0 }"
        @click="checkType = item.value"
      >
        {{ item.label }}
      </div>
    </div>
    <div
      v-if="examineItem.length > 0 || examItem.length > 0"
      class="task-box edit-scroll-style"
    >
      <template v-if="checkType == 1">
        <div v-for="(item, index) in examItem" :key="index" class="task-item">
          <div class="task-item-left">
            <span class="title">{{ item.examName }}</span>
            <span class="time">{{ setTime(item.examDate) }}</span>
          </div>
          <el-button type="text" @click="linkToMark(item)">开始阅卷</el-button>
        </div>
      </template>
      <template v-if="checkType == 2">
        <div
          v-for="(item, index) in examineItem"
          :key="index"
          class="task-item"
        >
          <div class="task-item-left">
            <el-tooltip
              placement="top"
              popper-class="taskItem-tips-box"
              :open-delay="300"
            >
              <div slot="content">
                <div class="tips-item">考试名称：{{ item.examName }}</div>
                <div class="tips-item">待审核学科：{{ item.subjectName }}</div>
                <div class="tips-item">
                  审核限时：
                  <template v-if="item.trainingTimeEffective == 0">
                    不限时
                  </template>
                  <template v-else>{{ getDeadlineCopy(item) }}</template>
                </div>
                <div class="tips-item">
                  已审核/全部题量：{{ item.isPass }} / {{ item.total }}
                </div>
              </div>
              <span class="title">{{ item.examName }}</span>
            </el-tooltip>

            <span class="time">
              <template v-if="item.trainingTimeEffective == 0">不限时</template>
              <template v-else>{{ getDeadline(item) }}</template>
            </span>
          </div>
          <el-button type="text" @click="linkToQuestion(item)"
            >前往审题</el-button
          >
        </div>
      </template>
    </div>
    <noData
      v-if="
        ((checkType == 1 && examItem.length == 0) ||
          (checkType == 2 && examineItem.length == 0)) &&
        !listLoading
      "
      :message="checkType == 1 ? '暂无待批阅试题' : '暂无待审核任务'"
    ></noData>
  </div>
</template>
<script>
//
import { examineList, markList } from "@/core/api/dashboard/index";
import moment from "moment";
const checkArr = [
  {
    value: 1,
    label: "待批阅",
    nums: 0,
  },
  {
    value: 2,
    label: "待审核",
    nums: 0,
  },
];

export default {
  name: "TaskItem",
  data() {
    return {
      checkArr: checkArr,
      checkType: 1,
      examineItem: [],
      examItem: [],
      listLoading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    getDeadlineCopy(row) {
      const trainingTimeEnd = new Date(row.trainingTimeEnd).getTime();
      const now = new Date().getTime();

      if (trainingTimeEnd > now) {
        return (
          moment(row.trainingTimeBegin).format("yyyy-MM-DD HH:mm") +
          " ~ " +
          moment(row.trainingTimeEnd).format("yyyy-MM-DD HH:mm")
        );
      } else {
        return `${moment(row.trainingTimeEnd).format(
          "yyyy-MM-DD HH:mm"
        )}（已截止）`;
      }
    },
    getDeadline(row) {
      const trainingTimeEnd = new Date(row.trainingTimeEnd).getTime();
      const now = new Date().getTime();

      if (trainingTimeEnd > now) {
        return moment(row.trainingTimeEnd).format("yyyy-MM-DD HH:mm");
      } else {
        return `${moment(row.trainingTimeEnd).format(
          "yyyy-MM-DD HH:mm"
        )}（已截止）`;
      }
    },
    setTime(value) {
      if (!value) {
        return "";
      }
      return value.split(" ")[0];
    },
    init() {
      this.getExamineList();

      if (this.$parent.teacherRoleId != 0) {
        this.getmarkList();
      }
      if (this.$parent.teacherRoleId == 0) {
        // this.checkType = 2;
      }
    },
    linkToMark(item) {
      this.$router.push({
        path: "/mark/mark-list",
        query: {
          examId: item.examId,
        },
      });
    },
    linkToQuestion(item) {
      this.$router.push({
        path: "/examine/list",
        query: {
          examId: item.examId,
        },
      });
    },
    getExamineList() {
      this.listLoading = true;
      examineList()
        .then((res) => {
          this.examineItem = res.data.data;

          this.checkArr[1].nums = this.examineItem.length;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 考试列表
    getmarkList() {
      this.listLoading = true;
      markList()
        .then((res) => {
          this.examItem = res.data.data;
          this.checkArr[0].nums = this.examItem.length;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.taskItem-tips-box {
  .tips-item {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.taskItem {
  line-height: 1;
  ::v-deep {
    .edit-scroll-style {
      &::-webkit-scrollbar {
        width: 3px !important;
        height: 3px !important;
      }
    }
  }

  .task-box {
    max-height: 162px;
  }
  .task-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    align-items: center;
    padding-right: 5px;
    .task-item-left {
      display: flex;
      align-items: center;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 14px;
      color: #0a1119;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      display: inline-block;
      margin-right: 2px;
    }
    .time {
      font-size: 12px;
      color: #83878f;
    }
  }
  .check-box {
    display: flex;
    scroll-behavior: smooth;
    margin-bottom: 18px;
    div {
      white-space: nowrap;
      line-height: 14px;
      padding: 6px 10px;
      margin-right: 8px;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 2px;
      position: relative;

      &:hover {
        background: #eff5ff;
        color: #2474ed;
      }
    }
    .check-nums {
      &::after {
        position: absolute;
        right: 4px;
        top: 1px;
        width: 6px;
        height: 6px;
        background-color: #ff3240;
        z-index: 1;
        content: "";
        border-radius: 50%;
      }
    }
    .on {
      background: #eff5ff;
      color: #2474ed;
    }
  }
}
</style>
