<template>
  <div class="moduleContents">
    <div class="sub-tile">平台各模块使用频次-教师</div>
    <div v-if="teacherRoleId == 0" class="check-item-box">
      <div
        v-for="(item, index) in levelOptions"
        :key="index"
        :class="{ on: item.value == levelId }"
        @click="checkLevel(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <template v-if="commonObj.list.length > 0 || listLoading">
      <div class="tips-msg">
        <div v-html="commonObj.description"></div>
      </div>
      <div v-show="$parent.chartType">
        <template v-if="teacherRoleId != 2">
          <div v-if="teacherRoleId != 3" style="margin-bottom: 18px">
            <el-select
              v-model="searchType"
              placeholder=""
              @change="checkSelete()"
            >
              <el-option label="模块使用频次" :value="1"> </el-option>
              <el-option label="使用人数（去重）" :value="2"> </el-option>
            </el-select>
          </div>
          <numberOfUse ref="numberOfUse"></numberOfUse>
        </template>
        <template
          v-if="teacherRoleId == 0 || teacherRoleId == 5 || teacherRoleId == 4"
        >
          <el-table
            :key="teacherRoleId"
            :data="tableData"
            border
            style="width: 100%"
          >
            <template v-if="teacherRoleId == 0">
              <el-table-column
                prop="subjectName"
                fixed
                align="center"
                label="学科"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="teacherNum"
                align="center"
                label="教师数量"
                width="90"
              >
              </el-table-column>
              <el-table-column
                prop="webLoginFrequency"
                align="center"
                label="平台登录 （次）"
                width="90"
              >
              </el-table-column>
            </template>
            <template v-if="teacherRoleId == 5 || teacherRoleId == 4">
              <el-table-column
                prop="subjectName"
                fixed
                align="center"
                label="学科"
                width="60"
              >
              </el-table-column>
              <el-table-column
                prop="teacherNum"
                align="center"
                label="平台教师数 （人）"
                width="90"
              >
              </el-table-column>
              <el-table-column
                prop="webLoginFrequency"
                align="center"
                label="登录次数 （次）"
                width="90"
              >
              </el-table-column>
            </template>

            <el-table-column
              v-for="(item, index) in modelArr"
              :key="index"
              align="center"
              :label="item.label"
            >
              <el-table-column
                :prop="item.keyFrequency"
                align="center"
                label="使用频次"
                width="80"
              >
              </el-table-column>
              <el-table-column
                :prop="item.keyCount"
                align="center"
                label="使用人数"
                width="80"
              >
              </el-table-column>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="teacherRoleId == 3 || teacherRoleId == 2">
          <el-table
            :key="teacherRoleId"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="teacherName"
              label="教师"
              fixed
              width="80"
              align="center"
            />
            <el-table-column
              v-if="teacherRoleId == 2"
              prop="subjectName"
              label="学科"
              width="60"
              align="center"
            />

            <el-table-column
              prop="webLoginFrequency"
              width="110"
              label="平台登录(次)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="resourceFrequency"
              label="资源中心"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="markFrequency"
              label="主观题阅卷"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="sheetFrequency"
              label="答题卡制作"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="reportFrequency"
              label="统计报告"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="formFrequency"
              label="统计报表"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="appLoginFrequency"
              label="App登录频次"
              width="119"
              align="center"
            >
            </el-table-column>
          </el-table>
        </template>
        <div
          v-if="commonObj.list.length > 5"
          class="more-box"
          :class="{ 'more-box-on': !showTab }"
          @click="checkMore()"
        >
          <div>
            <i class="el-icon-d-arrow-right"></i>
            <template v-if="!showTab"> 展开 </template>
            <template v-else> 收起 </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          <div>暂无教师使用数据</div>
        </div>
      </noData>
    </template>
  </div>
</template>
<script>
import numberOfUse from "./numberOfUseChart.vue";
import { levelOptions } from "@/core/util/constdata";
import { commonFrequency } from "@/core/api/dashboard/index";
const modelArr = [
  {
    label: "资源中心",
    keyFrequency: "resourceFrequency",
    keyCount: "resourceCount",
  },
  { label: "主观题阅卷", keyFrequency: "markFrequency", keyCount: "markCount" },
  {
    label: "答题卡制作",
    keyFrequency: "sheetFrequency",
    keyCount: "sheetCount",
  },
  { label: "统计报表", keyFrequency: "formFrequency", keyCount: "formCount" },
  {
    label: "统计报告",
    keyFrequency: "reportFrequency",
    keyCount: "reportCount",
  },
  {
    label: "App登录情况",
    keyFrequency: "appLoginFrequency",
    keyCount: "appLoginCount",
  },
];

export default {
  name: "ModuleContents",
  components: { numberOfUse },
  props: {
    titleName: {
      type: String,
      default: "",
    },
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      levelOptions: levelOptions(),
      searchType: 1,
      levelId: "",
      listLoading: false,
      showTab: false,
      modelArr: modelArr,
      tableData: [],
      commonObj: {
        list: [],
      },
    };
  },
  created() {
    this.levelId = this.levelOptions[0].value;
  },
  methods: {
    checkLevel(item) {
      if (this.listLoading) return;
      this.levelId = item.value;
      this.init();
    },
    checkSelete() {
      this.$refs.numberOfUse.init(this.commonObj.list);
    },
    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.commonObj.list;
      } else {
        this.tableData = this.commonObj.list.filter((item, index) => index < 5);
      }
    },
    getcommonFrequency(data) {
      this.listLoading = true;

      commonFrequency(data)
        .then((res) => {
          this.commonObj = res.data.data;
          this.commonObj.list = this.commonObj.list || [];

          this.initList();
          this.listLoading = false;
          this.$refs.numberOfUse?.init(this.commonObj.list);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    init() {
      let data = {
        roleType: this.teacherRoleId,
        level: this.levelId,
      };
      Object.assign(data, this.$parent.filterObj);
      this.getcommonFrequency(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.moduleContents {
  .more-box {
    margin-bottom: 18px;
  }
}
</style>
